<template>
    <header class="bg-transparent p-2">
        <div v-if="mobileWindow" class="bg:transparent flex flex-col items-left ">
            <button @click="
                showingNavigationDropdown =
                !showingNavigationDropdown
                "
                    class="fixed inline-flex items-center justify-center create rounded-md
                 text-gray-400/50 transition duration-150 ease-in-out
                   hover:text-gray-500
                  dark:text-gray-500
                   ">
                <svg class="h-10 w-10" stroke="currentColor" fill="none" viewBox="0 0 20 20">
                    <path :class="{
                        hidden: showingNavigationDropdown,
                        'inline-flex':
                            !showingNavigationDropdown,
                    }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M5 6h16M5 12h16M5 18h16M5"/>
                    <path :class="{
                        hidden: !showingNavigationDropdown,
                        'inline-flex':
                            showingNavigationDropdown,
                    }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
        </div>
        <component_hide  v-show="showingNavigationDropdown" class="fixed font-bold backdrop-blur-md font-serif rounded-3xl ml-20   md:ml-0 w-1/2 md:w-full
                                                     md:bg-transparent md:dark:bg-transparent shadow-lg shadow-black md:relative z-50
                                                     ">
            <div class="flex flex-col md:flex-row dark:text-gray-800  md:justify-between items-center md:items-stretch">
                <div class="dark:text-gray-200  text-2xl font-bold">
                    <Link :href="route('welcome')" class="hover:text-orange-400 md:p-4 text-3xl drop-shadow-lg">LOGO</Link>
                </div>
                <div
                    class="flex flex-col md:flex-row p-4 md:p-0 md:gap-0 font-semibold text-lg md:space-x-4 text-center font-sans
                     text-gray-900/80
                            dark:text-gray-800">
                    <Link :href="route('blog')" :class="headerLinkClass">Блог</Link>
                    <Link :href="route('service')" :class="headerLinkClass">Услуги</Link>
                    <Link :href="route('contact')" :class="headerLinkClass">Контакты</Link>
                    <Link :href="route('certificate')" :class="headerLinkClass">
                        Сертификаты
                    </Link>
                    <Link :href="route('answer')" :class="headerLinkClass">Ответы</Link>
                    <Link :href="route('about')" :class="headerLinkClass">Обо мне</Link>
                </div>
            </div>
        </component_hide>
    </header>
</template>

<script setup>
import {Link} from '@inertiajs/vue3';
import {ref, onMounted, onBeforeUnmount} from "vue";


const showingNavigationDropdown = ref(false)
const windowWidth = ref(window.innerWidth)
const mobileWindow = ref(false)

const handleResize = () => {
    windowWidth.value = window.innerWidth
    if (windowWidth.value > 800) {
        mobileWindow.value = false
        showingNavigationDropdown.value = true
    } else {
        showingNavigationDropdown.value = false
        mobileWindow.value = true
    }
}

const headerLinkClass = ref(String)

headerLinkClass.value = "p-2 px-2 text-2xl md:text-lg md:hover:text-orange-600 bg-slate-300/70 hover:bg-amber-200/80 mt-2 md:mt-0 shadow-inner shadow-black md:shadow-none md:bg-transparent rounded-lg drop-shadow-md md:dark:text-gray-300 md:hover:shadow-none md:hover:bg-transparent "

onMounted(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
});
onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
});
</script>
<style>
component_hide {
    opacity: 0;
    animation: ani 0.5s forwards ease-in-out;
}
</style>
